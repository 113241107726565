/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }

  .App-link {
    animation: App-logo-spin infinite 5s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

/* .body-main {
  background-image: url("Pictures/robinson-recalde-sT0n-Ie8OKo-unsplash.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  margin: 0;
} */

/* //////////////////// Layout one styles //////////////////// */

.layoutOne-content {
  background-image: url("Pictures/robinson-recalde-sT0n-Ie8OKo-unsplash.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  margin: 0;
  width: 100%;
  z-index: 0;
}

.layoutOne-header {
  width: 100%;
  height: 25vh;
}

.layoutOne-logo img {
  height: 15vh;
  padding: 2em;
}

.layoutOne-body {
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 75vh;
  justify-content: space-between;
  color: #ffffff;
}

.layoutOne-body-left {
  width: 50%;
  order: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 1;
}

.layoutOne-body-left-item {
  align-items: center;
  padding: 2em;
  position: relative;
  transition: 2s ease-in-out;
}

.layoutOne-body-left-item a {
  color: #ffffff;
  text-decoration: none;
}

.layoutOne-body-left-item::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 50%;
  height: 2px; /* Adjust the thickness of the underline */
  background-color: #ffffff; /* Adjust the color of the underline */
  transform-origin: bottom right;
  transform: scaleX(0); /* Initial state, no underline */
  transition: transform 2s ease; /* Transition property for smooth effect */
}

.layoutOne-body-left-item:hover::before {
  transform-origin: bottom left;
  transform: scaleX(1); /* Fully underline on hover */
}

/* .layoutOne-body-left-item:hover::after {
  content: attr(content); 
  top: 0;
  right: 50%; 
  white-space: nowrap; 
  opacity: 0; 
  transition: right 2s ease; 
  padding: 3em;
  border: #ffffff 2px solid;
  border-radius: 100px;
  display: flex;
  justify-content:flex-end;
  align-self: flex-end;
} */

.layoutOne-body-left-item:hover::before {
  width: 50%; /* Fully underline on hover */
}

.layoutOne-body-left-item:hover::after {
  right: 0; /* Move content to the right */
  opacity: 1; /* Make content visible on hover */
}

.layoutOne-body-right {
  width: 25%;
  order: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  top: 0;
  right: 0;
  width: 0;
  height: 100%;
  background-color: #ffffff;
  opacity: 0.5;
  overflow: hidden;
  transition: width 1s ease-in-out;
  border-radius: 25px;
}

.layoutOne-body-left:hover + .layoutOne-body-right {
  /* border: #ffffff 2px solid; */
  transition: 1s ease-in-out;
  width: 25%;
}

.layoutOne-body-right-container {
  padding: 25px;
  color: #000000;
  font-weight: bold;
}

/* .layoutOne-body-right::before {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 2px; 
  background-color: #ffffff; 
  transform-origin: bottom right;
  transform: scaleX(0); 
  transition: transform 2s ease; 
} */

.layoutOne-body-right:hover::before {
  width: 100%; /* Fully underline on hover */
  opacity: 0;
}

.layoutOne-body-right:hover::after {
  right: 0; /* Move content to the right */
  opacity: 1; /* Make content visible on hover */
}

.layoutOne-divider-line {
  width: 75%;
  background-color: #ffffff;
  height: 2px;
  text-align: left;
  margin-inline-start: 0;
}

/* //////////////////// Layout Two styles //////////////////// */

.layoutTwo-content {
  /* background-image: url("Pictures/max-mckinnon-c9OCWLka764-unsplash.jpg"); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  margin: 0;
  background-color: rgb(51 65 85);
}

.layoutTwo-logo {
  width: 100%;
  text-align: center;
}

.layoutTwo-logo img {
  height: 20vh;
  padding: 2em;
  align-self: center;
}

.layoutTwo-nav {
  width: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.layoutTwo-nav-item {
  margin-inline-start: 5em;
  margin-inline-end: 5em;
}

.layoutTwo-nav-item a {
  color: rgba(0, 0, 0, 0.8);
  transition: 0.4s;
  border-bottom: 2px solid transparent;
  text-decoration: none;
}

.layoutTwo-nav-item a:hover {
  font-weight: bolder;
  transition: width 2s ease;
  text-decoration: underline;
}

.layoutTwo-nav-item a:active {
  text-decoration: underline;
  text-decoration-color: #000000;
}

.layoutTwo-body-content {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
}

.layoutTwo-body-content-background {
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 70%;
  border-radius: 25px;
  background-color: #ffffff; /* White background with 50% opacity */
}

.layoutTwo-body-content-background-pic-container {
  /* height: 300px; */
  overflow: hidden;
  border-radius: 25px;
  width: 90%;
  height: 60vh;
  background-image: url("Pictures/matt-botsford-OKLqGsCT8qs-unsplash.jpg");
  background-size:cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  display: flex;
}

.layoutTwo-body-content-pic {
  height: 100%;
  justify-content: flex-start;
  align-self: center;
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: row;
}

/* .layoutTwo-body-content-pic img {
  width: 100%;
  border-radius: 25px;
  object-fit: cover;
  display: inline-block;
} */

.layoutTwo-body-content-pic-text {
  color: white;
  font-size: 18px;
  font-family: Arial, sans-serif;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
  width: 50%;
  order: 1;
  display: flex;
  align-items: center;
  padding: 25px;
}

.layoutTwo-body-content-space {
  height: 2em;
}

.layoutTwo-body-content-texts {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 90%;
  height: 50%;
  padding: 20px;
}

.layoutTwo-body-content-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgb(51 65 85);
  padding: 10px;
  color: #ffffff;
  border-radius: 25px;
  width: 30%;
}

.layoutTwo-footer {
  display: flex;
  justify-content: center;
  align-self: center;
  width: 100%;
  order: 3;
  height: 70px;
  background-color: #ffffff;
  text-align: center;
  margin-top: 30px;
}

/* //////////////////// Layout Three styles //////////////////// */

.layoutThree-content {
  background-image: url("Pictures/dariusz-sankowski-3OiYMgDKJ6k-unsplash.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  margin: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.layoutThree-logo {
  width: 100%;
  height: 100%;
  text-align: center;
  justify-content: center;
  display: flex;
  order: 2;
  flex-direction: column;

}

.layoutThree-logo-img-container {
  order: 1;
  justify-content: center;
  width: 100%;
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 0.493) 0%,
    rgba(255, 255, 255, 0.11)
  );
}

.layoutThree-logo-text {
  justify-content: center;
  order: 2;
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 0.493) 0%,
    rgba(255, 255, 255, 0.11)
  );
}

.layoutThree-logo-img-container img {
  height: 15rem;
  align-self: center;
  color: #000;
  padding: 15px;
}

.layoutThree-nav {
  display: flex;
  width: 100%;
  order: 1;
  flex-direction: row;
  justify-content: space-between;
  color: #000000;
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 0.493)50%,
    rgba(255, 255, 255, 0.11)
  );
}

.layoutThree-nav-item {
  position: relative;
  padding: 10px;
  display: flex;
  justify-content: center;
  width: 25%;
  z-index: 1;
}

.layoutThree-nav-item::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 0.493) 0%,
    rgba(255, 255, 255, 0.11)
  );
  z-index: -1;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.layoutThree-nav-item:hover::before {
  opacity: 1;
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 0.9) 0%,
    rgba(255, 255, 255, 0.11)
  );
}


.layoutThree-nav-item a {
  color: #000000;
  text-decoration: none;
}

.mouseTrail {
  position: absolute;
  height: 5px; 
  width: 5px;
  border-radius: 3px;
  background: #000000;
  box-shadow: 0px 0px 1px 1px #000000;
  z-index: 0;
  overflow:hidden;
  opacity: .5;
  
}

/* //////////////////// Layout Four styles //////////////////// */

.layoutFour-content {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100vh;
  justify-content: center;
}

.layoutFour-section-one {
  width: 30%;
  background-color: slategray;
  transition: width 2s ease-in-out;
}

.layoutFour-section-one:hover {
  width: 35%;
  transition: width 2s ease-in-out;
}

.layoutFour-nav {
  padding: 15px;
  color: snow;
  text-decoration: none;
}

.layoutFour-nav-item a {
  color: snow;
  text-decoration: none;
  transition: 2s ease-in-out;
}

.layoutFour-nav-item a:hover {
  transition: 2s ease-in-out;
  font-size: larger;
}

.layoutFour-section-two {
  width: 30%;
  transition: width 2s ease-in-out;
}

.layoutFour-section-two-image {
  background-image: url("Pictures/jeremy-bishop-246lShsjdv8-unsplash.jpg");
  background-size:cover;
  background-position: center;
  height: 100%;
  width: 100%;
}

.layout-four-section-two-text {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 20px;
  text-align: center;
  z-index: 2;
  opacity: 0;
  transition: opacity 0.5s ease-in-out; /* Apply transition effect on opacity */
}


.layoutFour-section-two:hover {
  width: 35%;
  transition: width 2s ease-in-out;
}

.layoutFour-section-two:hover .layout-four-section-two-text {
  opacity: 1;
}

.layoutFour-image-container img {
  height: 15rem;
  align-self: center;
  padding: 15px;
}

.layoutFour-section-three {
  width: 30%;
  background-color: snow;
  transition: width 2s ease-in-out;
}

.layoutFour-section-three:hover {
  width: 35%;
  transition: width 2s ease-in-out;
}

.layout-four-section-three-text {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 20px;
  text-align: center;
  flex-direction: column;
  z-index: 2;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;}

.layoutFour-section-three:hover .layout-four-section-three-text {
  opacity: 1;
}